import {spanishDateFormatter} from '../../ui/components/SpanishDateFormatter';
import {DealType} from '../domain/DealSelection.model';
import {Table} from '../../ui/components/Table';

export const BulkDealsSelectorUI = ({ bulkDeals, onDealSelection, selection }) => {
  if (!bulkDeals?.length) return null;

  /** @type {TableColumn[]} */
  const columns = [
    {
      header: "", // Checkbox column
      render: (deal) => (
        <input
          type="radio"
          name="bulkDeal"
          checked={selection?.value === deal}
          onChange={() => onDealSelection({ type: DealType.LOT, value: deal })}
        />
      ),
    },
    {
      header: "Precio",
      render: (deal) => `${deal.mejorpreciotachado.toFixed(2)}€`,
    },
    {
      header: "Descuento",
      render: (deal) => `${deal.THR_Discount__}%`,
    },
    {
      header: "Precio Neto",
      render: (deal) => `${deal.mejorprecio.toFixed(2)}€`,
    },
    {
      header: "Stock lote",
      render: (deal) => deal.Expired_Inventory,
    },
    {
      header: "Fecha de caducidad",
      render: (deal) => spanishDateFormatter(deal.THR_Expiration_Date),
    },
  ];

  return (
    <div>
      <strong className="m-0 text-primary">Promociones por lotes</strong>
      <Table columns={columns} data={bulkDeals} />
    </div>
  );
};