/**
 * @typedef {Object} TableColumn
 * @property {string} header - The column header text.
 * @property {(item: any) => JSX.Element | string} render - Function to render the column content.
 */

/**
 * @typedef {Object} TableProps
 * @property {TableColumn[]} columns - Array of table columns.
 * @property {any[]} data - Array of data to display in the table.
 */

/**
 *
 * @param {TableProps}
 * @returns {JSX.Element}
 * @constructor
 */
export const Table = ({ columns, data }) => {
  return (
    <div className="table-responsive">
      <table className="table table-borderless">
        <thead>
        <tr>
          {columns.map((col, index) => (
            <th key={index}>{col.header}</th>
          ))}
        </tr>
        </thead>
        <tbody className="fw-normal">
        {data.map((item, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((col, colIndex) => (
              <td key={colIndex}>{col.render(item)}</td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};